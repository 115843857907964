@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica/HelveticaNeueLTPro-UltLt.otf') format('otf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica/HelveticaNeueLTPro-Th.otf') format('otf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica/HelveticaNeueLTPro-Lt.otf') format('otf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica/HelveticaNeueLTPro-Roman.otf') format('otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica/HelveticaNeueLTPro-Md.otf') format('otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica/HelveticaNeueLTPro-Bd.otf') format('otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica/HelveticaNeueLTPro-Hv.otf') format('otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helvetica/HelveticaNeueLTPro-Blk.otf') format('otf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
