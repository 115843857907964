.pac-container {
  margin-top: 2px;

  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.14);
}

.pac-item {
  padding: 8px;

  font-size: 14px;
  color: #063f43;
  border: none;
  transition: all 0.2s ease;
}

.pac-item:hover {
  background-color: #e5f2f2;
  cursor: pointer;
}

.pac-item-query {
  font-size: inherit;
  color: inherit;
}

.hdpi.pac-logo:after,
.pac-icon {
  display: none;
}
